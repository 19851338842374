<template>
    <div>
        <img class="full-button" @click="makeFullScreen" :src="require(`@/assets/images/raycaster/${fullScreen.image}.svg`)">
    </div>
</template>

<script>

export default {
    name: 'FullScreenButton',
    data() {
        return {
            fullScreen: {
                image: 'full2',
            }
        }
    },
    props: {
        element: {
            type: HTMLDivElement,
        }
    },
    methods: {
        makeFullScreen() {
            if (!document.fullscreenElement) {
                this.element?.requestFullscreen();
            } else if (document.fullscreenElement) {
                document.exitFullscreen();
            }
       },
        updateView() {
            if ( document.fullscreenElement ) {
                this.fullScreen.image = 'close2';
                return;
            }
            this.fullScreen.image = 'full2';
        },
    },
    mounted() {
        addEventListener("fullscreenchange", this.updateView);
    },
    beforeDestroy() {
        removeEventListener("fullscreenchange", this.updateView);
    }
}
</script>

<style scoped>
    .full-button {
        position: absolute;
        cursor: pointer;
        top: 10px;
        right: 10px;
    }
</style>